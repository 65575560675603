<template>
  <div>
    
    <div id = "map">


      <v-card class = "card-stationDetails">
        <v-card-title class="card-header white--text px-10 py-8 text-subtitle-1 text-uppercase">
          STATION : {{ stationDetail.STATION_ID_A}}
          <br>
          LOCATION : {{ stationDetail.LOCATION}}
          <br>
          LAST UPDATED : <template  v-if = "stationDetail != ''">
            {{
                new Date(stationDetail.DATETIME_A).toLocaleDateString("en-GB") +
                  ", " +
                  new Date(stationDetail.DATETIME_A).toLocaleTimeString()
              }}
          </template>
        </v-card-title>

        <v-card-text>
          <v-simple-table class="px-0 py-4" light dense >
              <template v-slot:default>
                <thead>
                  <tr  style="background: #073662 !important; color: white">
                      <td> Parameter</td>
                      <td> Value & Unit </td>
                      <td colspan="2">Class Category</td>
                      <td> </td>
                     
                  </tr>
                </thead>
                <tbody>
                   <tr >
                      <td> pH </td>
                      <template v-if = " stationDetail.pH != null">
                          <td> {{ stationDetail.pH }} (Index) </td>
                          <td> <span :class="'dot-'+stationDetail.PARAM_PH_CLASS" ></span></td>
                          <td> Class {{ stationDetail.PARAM_PH_CLASS }} </td>
                      </template>
                      <template v-else >
                          <td></td><td></td><td></td>
                      </template>
                     
                  </tr>

                  <tr >
                      <td> Dissolved Oxygen (Saturation) </td>
                      <template v-if = " stationDetail.DO_SAT != null">
                          <td> {{ stationDetail.DO_SAT }} (Index) </td>
                          <td> <span :class="'dot-'+stationDetail.PARAM_DO_SAT_CLASS" ></span></td>
                          <td> Class {{ stationDetail.PARAM_DO_SAT_CLASS }} </td>
                      </template>
                      <template v-else >
                          <td></td><td></td><td></td>
                      </template>
                     
                  </tr>

                  <tr >
                      <td> Total Suspended Solids </td>
                      <template v-if = " stationDetail.TSS != null">
                          <td> {{ stationDetail.TSS }} (Index) </td>
                          <td> <span :class="'dot-'+stationDetail.PARAM_TSS_CLASS" ></span></td>
                          <td> Class {{ stationDetail.PARAM_TSS_CLASS }} </td>
                      </template>
                      <template v-else >
                         <td></td><td></td><td></td>
                      </template>
                     
                  </tr>

                  <tr >
                      <td> Water Level </td>
                      <template v-if = " stationDetail.WATER_LEVEL != null">
                          <td> {{ stationDetail.WATER_LEVEL }} (m) </td>
                          <td></td><td></td>
                      </template>
                      <template v-else >
                          <td></td><td></td><td></td>
                      </template>                     
                  </tr>

                  <tr >
                      <td> Flowrate</td>
                      <template v-if = " stationDetail.FLOWRATE != null">
                          <td> {{ stationDetail.FLOWRATE }} (m3/s) </td>
                          <td></td><td></td>
                      </template>
                      <template v-else >
                          <td></td><td></td><td></td>
                      </template>     
                     
                  </tr>
                  
                  
                </tbody>
              </template>
            </v-simple-table>
        </v-card-text>
      </v-card>

        

    </div>
    

  </div>
</template>

<script>
// -- import something here --

import "ol/ol.css";

import Map from "ol/Map";
import View from "ol/View";
import { defaults as defaultControls, ScaleLine } from "ol/control";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Group as GroupLayer,
  Image as ImageLayer
} from "ol/layer";
import { OSM, Vector as VectorSource, ImageArcGISRest} from "ol/source";
import { Fill, Stroke, Circle, Style, Icon } from "ol/style";
import Feature from "ol/Feature";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import Overlay from "ol/Overlay";

// Axios for fetching data
import axios from "axios";

export default {

  data: () => ({

    mapIcon: {
            green: new Style({
                image: new Circle({
                    fill: new Fill({
                        color: "#00ff08",
                    }),
                    stroke: new Stroke({
                        color: "#ffffff ",
                        width: 3,
                    }),
                    radius: 10,
                    }),
            }),
            red: new Style({
                image: new Circle({
                    fill: new Fill({
                        color: "#fb4343",
                    }),
                    stroke: new Stroke({
                        color: "#fb4343",
                        width: 1.25,
                    }),
                    radius: 8,
                    }),
            }),
            orange: new Style({
                image: new Circle({
                    fill: new Fill({
                        color: "#fc8414",
                    }),
                    stroke: new Stroke({
                        color: "#fc8414",
                        width: 1.25,
                    }),
                    radius: 8,
                    }),
            }),
            grey: new Style({
                image: new Circle({
                    fill: new Fill({
                        color: "#7c8a94",
                    }),
                    stroke: new Stroke({
                        color: "#7c8a94",
                        width: 1.25,
                    }),
                    radius: 8,
                    })
            }),
            cyan: new Style({
                image: new Circle({
                    fill: new Fill({
                        color: "#078a88",
                    }),
                    stroke: new Stroke({
                        color: "#078a88",
                        width: 1.25,
                    }),
                    radius: 8,
                    })
            }),
        },

    //Declaration

    stationDetail: '',

    
    // Map Declaration

    map: null,
    view: null,
    extent:  [ 102.74131288335045, 1.4281723365439034, 103.94465766511522, 2.4005721601922105 ],
    baseMapLayer: {
      topographicMap: new TileLayer({
        title: "Topographic Map",
        source: new OSM({
          url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
       boundaryLayer: new ImageLayer({
        title: "Topographic Map",
        source: new ImageArcGISRest({
          url:
            "https://eqmp.scienotech.com.my/arcgis/rest/services/DEMARCATION/STATE/MapServer",
        }),
        visible: false,
        type: "layer",
      }),
      imageryMap: new TileLayer({
        title: "Imagery Map",
        source: new OSM({
          // "url" : 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
          url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          // "url": 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
        }),
        visible: true,
        type: "base",
      }),
      streetMap: new TileLayer({
        title: "OSM",
        source: new OSM({
          url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
      googleStreetMap: new TileLayer({
        title: "Google Street",
        source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
          // "url": 'http://mt1.googleapis.com/vt?x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
      googleSateliteMap: new TileLayer({
        title: "OSM",
        source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
    },
    pointStyle: {
      default: new Style({
        image: new Circle({
          fill: new Fill({
            color: "#3381ff",
          }),
          stroke: new Stroke({
            color: "#0242ab",
            width: 1.25,
          }),
          radius: 8,
        }),
      }),
      selected: new Style({
        image: new Circle({
          fill: new Fill({
            color: "#00ffff",
          }),
          stroke: new Stroke({
            color: "#003300",
            width: 1.25,
          }),
          radius: 8,
        }),
      }),
      
    }
  }),

  mounted() {
    this.initiateMap();
    this.getParamInfo();
    this.transition();
  },

  methods: {

    transition(){
      setTimeout(()=> {
         this.$router.push({ path: '/PublicDisplay/WQMS06' }).catch(()=>{});
      }
      ,5000);
    },

    initiateMap() {
      var source = new VectorSource();
      var vector = new VectorLayer({
        source: source,
      });

      var baseMap = new GroupLayer({
        layers: [
          this.baseMapLayer.topographicMap,
          this.baseMapLayer.imageryMap,
          this.baseMapLayer.streetMap,
          this.baseMapLayer.googleStreetMap,
          this.baseMapLayer.googleSateliteMap,
        ],
      });

      this.map = new Map({
        controls: defaultControls({
          zoom: false,
        }),
        target: "map",
        layers: [baseMap],
        view: new View({
          projection: "EPSG:4326",
          zoom: 1,
        }),
      });

      this.view = this.map.getView();
      this.view.fit(this.extent);

      this.map.on('moveend', this.onMoveEnd)

      this.onClickMap();

    },

    onClickMap() {
        let that = this;

        this.map.on("click", function(evt) {
            console.log("hehe");
        });
    },

     onMoveEnd(evt) {

              console.log(evt.pixel);
              // console.log(this.view);

                const map = evt.map;
                console.log("extent2", map.getView().calculateExtent())
                // console.log(fromLonLat(evt.coordinate, 'EPSG:3857', 'EPSG:4326'));
    },

    zoomStation(longitude, latitude) {
      this.view.animate({
          center:  [longitude, latitude],
          duration: 2000,
          zoom: 15,
        });
    },


    getParamInfo() {
      axios
        .get(this.globalUrl + "/bakaj/params", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.paramDetails = response.data;

          this.getStationInfo();
          
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getStationInfo() {
      axios
        .get(this.globalUrl + "/bakaj/reading", {
        // .get("https://run.mocky.io/v3/98873f4d-ebf7-44ff-9565-4fa2674af35c", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.stationDetails = response.data;

          this.addMapMarker();

        //   this.getStationInfoList();

        })
        .catch((error) => {
          console.log(error);
        });
    },

    addMapMarker() {
    //   this.addborderLayer();
      
      for (let i = 0; i < this.stationDetails.length; i++) {
        var marker = new Feature({
          geometry: new Point([
            this.stationDetails[i].LONGITUDE,
            this.stationDetails[i].LATITUDE,
          ]),
          description: this.stationDetails[i],
        });
        var vectorSource = new VectorSource({
          features: [marker],
        });

     

        var markerVectorLayer = new VectorLayer({
          name: "Automated",
          source: vectorSource,
          style:    this.stationDetails[i].GREEN == "TRUE"
                    ? this.mapIcon.green
                    : this.stationDetails[i].GREY == "TRUE" 
                    ? this.mapIcon.grey
                    : this.stationDetails[i].YELLOW == "TRUE"
                    ? this.mapIcon.green
                    : this.mapIcon.green,
        });

        this.map.addLayer(markerVectorLayer);
      }
      // this.addMapOverlayReading();

      this.addMapOverlay();
    },

     addMapOverlay() {
      for (let i = 0; i < this.stationDetails.length; i++) {

        let labelStationID = "label_" + this.stationDetails[i].STATION_ID_A;

        let divLabel = document.createElement("DIV");

        divLabel.setAttribute("id", labelStationID);
        divLabel.setAttribute("ref", labelStationID);

        divLabel.setAttribute("style", "background-color:#5A5A5A;color: white !important;z-index: 100; letter-spacing: 1px; padding: 4px;");

        let positioning 

         if (this.stationDetails[i].STATION_ID_A == 'WQMS06' || this.stationDetails[i].STATION_ID_A == 'WQMS05' || this.stationDetails[i].STATION_ID_A == 'WQMS01' )
            positioning = [-110, -15]
         else if (this.stationDetails[i].STATION_ID_A == 'WQMS03' || this.stationDetails[i].STATION_ID_A == 'WQMS04')
            positioning = [100, -13]
         else if (this.stationDetails[i].STATION_ID_A == 'WQMS02' )
            positioning = [123, -13]
         else   
            positioning = [0, -55]

          

        divLabel.innerHTML = this.stationDetails[i].STATION_ID_A + ", " + this.stationDetails[i].LOCATION;

        document.getElementById("map").appendChild(divLabel);


        let overlayLabel = new Overlay({
          element: document.getElementById(labelStationID),
          id: "label__" + this.stationDetails[i].STATION_ID_A,
          positioning: "top-center",
          offset: [-110, -15]
        });

        overlayLabel.setPosition([
          this.stationDetails[i].LONGITUDE,
          this.stationDetails[i].LATITUDE,
        ]);

        this.map.addOverlay(overlayLabel);
      }

      this.getStationDetail('WQMS05');
    },

    getStationDetail(station_id) {
      for(let i = 0 ; i < this.stationDetails.length ; i++){
          if( this.stationDetails[i].STATION_ID_A == station_id) {
            this.stationDetail = this.stationDetails[i];
          }
      }

      this.zoomStation(this.stationDetail.LONGITUDE, this.stationDetail.LATITUDE)

      
    }
    
  },

};
</script>

<style lang="scss" scoped>
@import '~scss/main';


#map {
  position: fixed;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
//   background: black;
}

.card-stationDetails {
  position: absolute;
  top: 50px;
  right: 40px;
  z-index: 15;
  width: 550px;
  height: 430px;
}

#map::v-deep .dot-I {
  height: 12px;
  width: 12px;
  background-color: #2d7ef8;
  border-radius: 50%;
  display: inline-block;
}

#map::v-deep .dot-II {
  height: 12px;
  width: 12px;
  background-color: #05F61D;
  border-radius: 50%;
  display: inline-block;
}

#map::v-deep .dot-III {
  height: 12px;
  width: 12px;
  background-color: #F5E700;
  border-radius: 50%;
  display: inline-block;
}

#map::v-deep .dot-IV {
  height: 12px;
  width: 12px;
  background-color: #F6C105;
  border-radius: 50%;
  display: inline-block;
  animation: blinker 2s linear infinite;
}

#map::v-deep .dot-V {
  height: 12px;
  width: 12px;
  background-color: #F60505;
  border-radius: 50%;
  display: inline-block;
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}





</style>


